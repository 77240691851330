import React from 'react'
import { Box } from "@rebass/grid";

import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import H2 from "../components/H2";
import Button from "../components/Button";
import Paragraph from "../components/Paragraph";

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    if(posts.length === 0) {
      return (
        <Paragraph>Wij hebben nog een artikelen geschreven.</Paragraph>
      )
    }

    return (
      <>
        {posts &&
          posts.map(({ node: post }) => (
            <Box mb={[5, 6, 6]} key={post.id}>
              <article className="tile is-child box notification">
                <p>
                  <div>
                    <strong>{post.frontmatter.date}</strong>
                  </div><br />
                  <Link
                    to={post.fields.slug}
                  >
                    <H2>{post.frontmatter.title}</H2>
                  </Link>
                </p>
                <Paragraph>
                  {post.excerpt}
                  <br />
                  <br />
                  <Button as={Link} withArrow className="button" to={post.fields.slug}>
                    Verder lezen
                  </Button>
                </Paragraph>
              </article>
            </Box>
          ))}
      </>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
