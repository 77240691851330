import React from "react";
import { Flex, Box } from "@rebass/grid";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import Container from "../../components/Container";
import H1 from "../../components/H1";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Container>
          <Flex>
            <Box width={[1, 2 / 3, 1 / 2]} my={6}>
              <H1 as="h1">Nieuwste berichten</H1>
            </Box>
          </Flex>
          <BlogRoll />
        </Container>
      </Layout>
    );
  }
}
